import React from "react";
import { Menu } from "antd";
import dayjs from "dayjs";
import {
    UserOutlined,
    CalendarOutlined,
    SettingOutlined,
    // AreaChartOutlined,
    CheckCircleOutlined,
    BarChartOutlined,
    LineChartOutlined,
    HistoryOutlined,
    PushpinOutlined,
    CreditCardOutlined,
    HourglassOutlined,
    GiftOutlined,
    TeamOutlined,
    // ShareAltOutlined
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";

const SideMenu = (props) => {
    const allLocations = [
        "/checked-in",
        "/appointments",
        "/new-appointments",
        "/calendar",
        "/time-clock",
        "/clients",
        "/history",
        "/settings",
        "/settings/hours",
        "/settings/appointment-settings",
        "/settings/taxes",
        "/settings/menu",
        "/settings/staffs",
        "/reports/statistics",
        "/reports/billing",
        "/reports/client-stats",
        "/referral",
        "/gift-card"
    ];
    const locations = props.history.location.pathname;
    const currentLocation =
        allLocations.filter((location) => locations.includes(location))[0] ||
        "/appointments";

    const items = [
        {
            label: <NavLink className="sidemenu-link" to={"/appointments?date=" + dayjs().format("YYYY-MM-DD")}> Appointments</NavLink>,
            key: "/appointments",
            icon: <PushpinOutlined />
        },
        {
            label: <NavLink className="sidemenu-link" to="/checked-in?status=Pending"> Checked-in</NavLink>,
            key: "/checked-in",
            icon: <CheckCircleOutlined />
        },

        {
            label: <NavLink className="sidemenu-link" to={"/calendar"}> Calendar</NavLink>,
            key: "/calendar",
            icon: <CalendarOutlined />
        },
        {
            label: <NavLink className="sidemenu-link" to="/clients"> Clients</NavLink>,
            key: "/clients",
            icon: <UserOutlined />
        },
        {
            label: <NavLink className="sidemenu-link" to="/gift-card"> Gift Card</NavLink>,
            key: "/gift-card",
            icon: <GiftOutlined />
        },
        {
            label: <NavLink className="sidemenu-link" to="/time-clock"> Time Clock</NavLink>,
            key: "/time-clock",
            icon: <HourglassOutlined />
        },
        {
            label: <NavLink className="sidemenu-link" to="/history"> History</NavLink>,
            key: "/history",
            icon: <HistoryOutlined />
        },
        {
            label: "Reports",
            key: "sub2",
            icon: <BarChartOutlined />,
            children: [
                {
                    label: <NavLink to="/reports/statistics"> Statistics</NavLink>,
                    key: "/reports/statistics",
                    icon: <LineChartOutlined />
                },
                {
                    label: <NavLink to="/reports/billing"> Billing</NavLink>,
                    key: "/reports/billing",
                    icon: <CreditCardOutlined />
                },
                {
                    label: <NavLink to="/reports/client-stats"> Clients</NavLink>,
                    key: "/reports/client-stats",
                    icon: <TeamOutlined />
                }
            ]
        },
        {
            label: <NavLink className="sidemenu-link" to="/settings"> Settings</NavLink>,
            key: "/settings",
            icon: <SettingOutlined />
        }
    ]

    return (
        <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[currentLocation]}
            defaultSelectedKeys={[currentLocation]}
            onClick={() => props.handleCloseDrawer()}
            items={items}
        />
    );
};

export default withRouter(SideMenu);
