import API from "../../../utils/API/API";
import {
    AUTHENTICATE_USER_FAILURE,
    AUTHENTICATE_USER_SUCCESS,
    AUTHENTICATE_USER_STARTED,
    FORGOT_PASSWORD_STARTED,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    UPDATE_PASSWORD_STARTED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    RESET_PASSWORD_STARTED,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    CANCEL_ACTIVATE_ACCOUNT,
    LOGOUT_ALL_USER,
    LOGOUT,
    LOGOUT_ADMIN,
    REMOVE_ERROR_MESSAGE,
} from "../../constants/salon-account/authenticate";

import Cookies from "js-cookie";

const user = Cookies.getJSON("user") || {
    authenticated: false,
};

var isRequiredActivation = !!Cookies.getJSON("_ar") || false;
//var isPinVerified = !!Cookies.getJSON("_pt") || false;

const initialState = {
    user: user,
    isRequiredActivation,
    //isPinVerified,
    loading: false,
    error: null,
    message: null,
    component: "",
};

/**
 * Handle Login and Logout
 * User object with token will be stored in localStorage
 * @param {*} state
 * @param {*} action
 */
export default function authenticate(state = initialState, action) {
    switch (action.type) {

        case CANCEL_ACTIVATE_ACCOUNT:
            Cookies.remove("user")
            localStorage.clear();
            API.defaults.headers.common["Authorization"] = `Bearer `;
            return {
                ...state,
                isRequiredActivation: false,
                user: {
                    authenticated: false,
                }
            }

        case AUTHENTICATE_USER_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };

        case AUTHENTICATE_USER_SUCCESS:
            //Make API to server and set the new USer as an example
            var newUser = {
                authenticated: true,
                // token: action.payload.user.token,
            };

            Cookies.set("user", JSON.stringify(newUser), { expires: 15 });
            // localStorage.setItem("user", JSON.stringify(newUser));
            // const token = newUser.token;
            const data = action.payload.data;
            if (data.notRequiredAdminPasscode) {
                localStorage.setItem('pinPassed', JSON.stringify(data.notRequiredAdminPasscode))
            }
            if (data && data.isRequiredActivation) {
                isRequiredActivation = true;
                return {
                    ...state,
                    isRequiredActivation,
                    error: null,
                    loading: false,
                }
            }
            if (data && data.business) {
                localStorage.setItem('business', JSON.stringify(data.business))
            }
            //set API header for server side request authentication
            // API.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            return {
                ...state,
                user: newUser,
                error: null,
                loading: false,
            };

        case AUTHENTICATE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: "Incorrect Email or Password!",
            };
        //User logout
        //remove all the cookies
        case LOGOUT:
            Cookies.remove("user");
            Cookies.remove("_cb");
            Cookies.remove("_mb");
            Cookies.remove("_pt");
            localStorage.clear();
            API.defaults.headers.common["Authorization"] = `Bearer `;
            let user_logout = {
                authenticated: false,
            };
            return {
                ...state,
                user: user_logout,
                isRequiredActivation: false,
                loading: false,
                error: null
            };
        case LOGOUT_ADMIN:
            return {
                ...state,
                loading: false,
                message: "Logout as admin successfully!",
                error: null
            };
        case LOGOUT_ALL_USER:
            Cookies.remove("user");
            localStorage.clear();
            API.defaults.headers.common["Authorization"] = `Bearer `;
            let user_logout_all = {
                authenticated: false,
            };
            return {
                ...state,
                user: user_logout_all,
                isRequiredActivation: false,
                loading: false,
                error: null,
            };
        case FORGOT_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
                component: "ForgotPassword",
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message:
                    "A reset password instruction was sent to your email if it is in our database!",
            };
        case FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                message:
                    "A reset password instruction was sent to your email if it is in our database!",
            };
        case UPDATE_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                message: null,
                error: null,
                component: "",
            };
        case UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: "Your Password was updated",
            };
        case UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                message: "",
            };
        case RESET_PASSWORD_STARTED:
            return {
                ...state,
                loading: true,
                error: null,
                message: null,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message:
                    "Password has been reset successfully. Please log in again!",
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                error: "There is an error. Cannot reset your password!",
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                loading: false,
                error: null,
                message: null,
            };
        default:
            return { ...state };
    }
}
