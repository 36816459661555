import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Row, Col, Drawer } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import "./MenuContainer.css";
import ContentContainer from "../Content/ContentContainer";
import { logout } from "../../redux/actions/salon-account/authenticate";
import PageHeaderContainer from "./PageHeader/PageHeaderContainer";
import SideMenu from "./SideMenu";
import { collapseMenu } from "../../redux/actions/responsive/responsive";
import { DashBookingLogo, DashBookingSmallLogo } from "./DashBookingLogo";
const { Header, Sider, Content } = Layout;

class MenuContainer extends Component {
    state = {
        collapsed: false,
        currentLocation: "",
        drawerVisible: false
    };

    componentDidMount() {
        //get the current location and high light the side menu appropriately
        // const innerWidth =   window.innerWidth
        // if(innerWidth < 1200) {
        //     this.setState({
        //         collapsed: true
        //     })
        //     this.props.collapseMenu(true)
        // }
        let { currentLocation } = this.state;
        const allLocations = [
            "/account",
            "/checked-in",
            "/appointments",
            "/new-appointments",
            "/calendar",
            "/time-clock",
            "/clients",
            "/history",
            "/settings",
            "/settings/appointment-settings",
            "/settings/hours",
            "/settings/taxes",
            "/settings/menu",
            "/settings/staffs",
            "/reports/statistics",
            "/reports/billings",
            "/referral",
            "/gift-card"
        ];
        const locations = this.props.history.location.pathname;
        currentLocation =
            allLocations.filter((location) =>
                locations.includes(location)
            )[0] || "/appointments";
        this.setState({
            currentLocation,
        });

        //get business country for phone format
        const country = JSON.parse(localStorage.getItem("business"))?.country || "CA"
        localStorage.setItem("country", JSON.stringify({ country }))
    }

    toggle = () => {
        const { isMobile, isTablet } = this.props
        if (!isMobile && !isTablet) {
            this.props.collapseMenu(!this.state.collapsed)
            this.setState({
                collapsed: !this.state.collapsed,
            });
        } else {
            this.setState({
                drawerVisible: true
            })
        }
    };

    handleRedirect = (location) => {
        let { currentLocation } = this.state;
        currentLocation = location;

        this.setState({
            currentLocation,
        });
    };

    handleCloseDrawer = () => {
        this.setState({
            drawerVisible: false
        })
    }

    render() {
        const { currentLocation, drawerVisible } = this.state;

        return (
            (<Layout style={{ height: "100%" }}>
                {!this.props.isMobile && !this.props.isTablet
                    ? <Sider
                        trigger={null}
                        collapsible
                        collapsed={this.props.isMenuCollapsed}
                    >
                        <div className="logo">

                            {this.props.isMenuCollapsed ? <DashBookingSmallLogo /> : <DashBookingLogo />}
                        </div>
                        <SideMenu
                            currentLocation={currentLocation}
                            handleRedirect={this.handleRedirect}
                            handleCloseDrawer={this.handleCloseDrawer}
                        />
                    </Sider>
                    : <Drawer
                        closable={false}
                        placement="left"
                        open={drawerVisible}
                        onClose={this.handleCloseDrawer}
                        bodyStyle={{ background: "#001529" }}
                    >
                        <div className="logo" style={{ margin: '0px', padding: "10px", background: "#001529" }} >
                            <DashBookingLogo />
                        </div>
                        <SideMenu
                            currentLocation={currentLocation}
                            handleRedirect={this.handleRedirect}
                            handleCloseDrawer={this.handleCloseDrawer}
                        />
                    </Drawer>}
                <Layout className="site-layout">
                    <Header
                        className="site-layout-background"
                        style={{ padding: 0 }}
                    >
                        <Row justify="start">
                            <Col span={3}>
                                {React.createElement(
                                    this.props.isMenuCollapsed
                                        ? MenuUnfoldOutlined
                                        : MenuFoldOutlined,
                                    {
                                        className: "trigger",
                                        onClick: this.toggle,
                                    }
                                )}
                            </Col>
                            <Col span={21} >
                                {/* Page title defined by current location
                                    Contain notification and account setting
                                */}
                                <PageHeaderContainer history={this.props.history} />
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={!this.props.isMobile
                            ? {
                                margin: "24px 0px 5px 5px",
                                padding: "5px 5px 5px 10px",
                            }
                            : {
                                margin: "24px 0px 5px 5px",
                                padding: "5px",
                            }
                        }
                    >
                        <ContentContainer
                            pathname={this.props.history.location.pathname}
                            history={this.props.history}

                        />
                    </Content>
                </Layout>
            </Layout>)
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.authenticate.user,
        isMenuCollapsed: state.responsive.isMenuCollapsed,
        isMobile: state.responsive.isMobile,
        isTablet: state.responsive.isTablet,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logout()),
        collapseMenu: (isMenuCollapsed) => dispatch(collapseMenu(isMenuCollapsed))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
