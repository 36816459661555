import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { Result, Button } from "antd";
import { Switch, Route } from "react-router-dom";
import Loading from "../../component/common/Loading/Loading";


class ContentContainer extends Component {
    render() {
        const { isMobile } = this.props
        const Profile = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Account/Profile`));
        const CheckedIn = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/CheckedIn/CheckedIn`));
        const ClientRoutes = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Client/ClientRoutes`));
        const AppointmentRoutes = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Appointment/AppointmentRoutes`));
        const UpcomingAppointments = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/UpcomingAppointments/UpcomingAppointments`));
        const PinAuth = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/PinAuth/PinAuth`));
        const TimeClock = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/TimeClock/TimeClock`));
        const History = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/History/History`));
        const Sales = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Report/Sales`));
        const Billing = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Report/Billing`));
        // const Referral = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Referral/Referral`));
        const Invoice = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Invoice/Invoice`));
        const Checkout = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Checkout/Checkout`));
        const SettingRoutes = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Settings/SettingRoutes`));
        const GiftCardHistory = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/GiftCardHistory/GiftCardHistory`));
        const EditInvoice = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Invoice/EditInvoice`));
        const ClientsStats = React.lazy(() => import(`../../${isMobile ? "pageMobile" : "page"}/Report/ClientsStats`))

        return (

            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route
                        path='/'
                        exact
                        render={props => <AppointmentRoutes {...props} />}
                    />
                    <Route
                        path='/checked-in'
                        exact
                        render={props => <CheckedIn {...props} />}
                    />

                    <Route
                        path='/check-out'
                        exact
                        render={props => <Checkout {...props} />}
                    />
                    <Route
                        path='/appointments'
                        render={props => <AppointmentRoutes {...props} />}
                    />
                    <Route
                        path='/new-appointments'
                        render={props => <AppointmentRoutes {...props} />}
                    />
                    <Route
                        path='/calendar'
                        render={props => <UpcomingAppointments {...props} />}
                    />
                    <Route
                        path='/time-clock'
                        render={props => <TimeClock {...props} />}
                    />
                    <Route
                        path='/clients'
                        render={props => <ClientRoutes {...props} />}
                    />
                    <Route
                        path='/history'
                        exact
                        render={props => <History {...props} />}
                    />
                    <Route
                        path='/gift-card'
                        exact
                        render={props => <GiftCardHistory {...props} />}
                    />
                    <Route
                        path='/settings'
                        render={props => <SettingRoutes {...props} />}
                    />
                    <Route
                        path='/reports/billings'
                        render={props => <Billing {...props} />}
                    />
                    <Route
                        path='/reports/statistics'
                        render={props => <Sales {...props} />}
                    />
                    <Route
                        path='/reports/billing'
                        render={props => <Billing {...props} />}
                    />
                    <Route
                        path='/reports/client-stats'
                        render={props => <ClientsStats {...props} />}
                    />

                    <Route
                        path='/invoices'
                        render={props => <Invoice {...props} />}
                    />
                    <Route
                        path='/edit-invoice'
                        render={props => <EditInvoice {...props} />}
                    />
                    <Route
                        path='/account'
                        exact
                        render={props => <Profile {...props} />}
                    />
                    <Route
                        path='/pin-auth'
                        exact
                        render={props => <PinAuth {...props} />}
                    />
                    {/* <Route
                        path='/referral'
                        exact
                        render={props => <Referral {...props} />}
                    /> */}
                    <Route
                        path='/*'
                        render={props => (
                            <Result
                                status='404'
                                title='404'
                                subTitle='Sorry, the page you visited does not exist.'
                                extra={
                                    <Button
                                        type='primary'
                                        className='animated bounceInLeft'
                                        onClick={() => props.history.goBack()}
                                        shape='round'
                                    >
                                        Back Home
                                    </Button>
                                }
                            />
                        )}
                    />
                </Switch>
            </Suspense>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        isMobile: state.responsive.isMobile,
    };
};

function mapDispatchToProps(dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);