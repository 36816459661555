import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { HomeTwoTone, DownOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import API from "../../../utils/API/API";
import { NewMessageError } from "../../../component/common/MessageError/NewMessageError";
import decodeHtmlEntities from "../../../utils/UnexcapeString/UnexcapeString";

import { Dropdown, Typography } from "antd";
const { Text } = Typography;
const Locations = (props) => {
    const [locations, setLocations] = useState([]);
    const [currentLocation, setCurrentLocation] = useState(null);
    const { isMobile } = useSelector((state) => state.responsive);

    useEffect(() => {
        getLocations();
    }, []);



    // const getLocations = () => {
    //     let locations = Cookies.getJSON("_mb") || (locationsLocalStorage && JSON.parse(locationsLocalStorage)) || null;
    //     let currentLocation = Cookies.getJSON("_cb") || (currnetLocationLocalStorage && JSON.parse(currnetLocationLocalStorage)) || null;
    //     const locationsLocalStorage = localStorage.getItem("_mb");
    //     const currnetLocationLocalStorage = localStorage.getItem("_cb");

    //     if (Array.isArray(locations) && currentLocation) {
    //         !locationsLocalStorage && localStorage.setItem('_mb', JSON.stringify(locations))
    //         !currnetLocationLocalStorage && localStorage.setItem('_cb', JSON.stringify(currentLocation))
    //         const foundMatchLocation = locations.filter(
    //             (location) => location._id === currentLocation
    //         )[0];
    //         if (!foundMatchLocation) {
    //             Cookies.remove("_mb");
    //             return null;
    //         }
    //         setCurrentLocation(currentLocation);
    //         setLocations(locations);
    //     }
    // };

    const getLocations = () => {
        // Get data from localStorage
        let locationsLocalStorage = localStorage.getItem("_mb");
        let currentLocationLocalStorage = localStorage.getItem("_cb");

        // If data doesn't exist in localStorage, get it from Cookies
        const locations = locationsLocalStorage ? JSON.parse(locationsLocalStorage) : Cookies.getJSON("_mb") || null;
        const currentLocation = currentLocationLocalStorage ? JSON.parse(currentLocationLocalStorage) : Cookies.getJSON("_cb") || null;

        if (Array.isArray(locations) && currentLocation) {
            // If data doesn't exist in localStorage, set it
            !locationsLocalStorage && localStorage.setItem('_mb', JSON.stringify(locations));
            !currentLocationLocalStorage && localStorage.setItem('_cb', JSON.stringify(currentLocation));

            const foundMatchLocation = locations.filter(
                (location) => location._id === currentLocation
            )[0];

            if (!foundMatchLocation) {
                Cookies.remove("_mb");
                return null;
            }

            setCurrentLocation(currentLocation);
            setLocations(locations);
        }
    };

    const handleChangeLocation = (locationId) => {
        API.post("/api/accounts/change-location", { locationId })
            .then((res) => {
                localStorage.setItem("_cb", JSON.stringify(locationId));
                // Specify the keys of the items you want to keep
                const keysToKeep = ['_mb', '_cb'];

                // Retrieve the values of the items to keep
                const itemsToKeep = {};
                keysToKeep.forEach(key => {
                    itemsToKeep[key] = localStorage.getItem(key);
                });

                localStorage.clear();
                // Reinsert the saved items back into local storage
                Object.keys(itemsToKeep).forEach(key => {
                    localStorage.setItem(key, itemsToKeep[key]);
                });

                if (res.data && res.data.isRequiredActivation) {
                    window.location.reload();
                }
                if (res.data && res.data.business && !res.data.isRequiredActivation) {
                    localStorage.setItem(
                        "business",
                        JSON.stringify(res.data.business)
                    );
                    props.history.push("/appointments");

                    window.location.reload();
                }


            })
            .catch((err) => {
                NewMessageError(null, "Cannot select the location!");
            });
    };

    const foundCurrentLocation =
        locations.find((location) => location._id === currentLocation) || null;

    const items = locations.map((location) => ({
        label: (
            <span
                style={{
                    color: location._id === currentLocation ? "#4096ff" : "",
                }}
            >
                <Text ellipsis={true} style={{
                    fontWeight: "bold",
                    color: location._id === currentLocation ? "#4096ff" : "", textOverflow: "ellipsis",
                    maxWidth: "220px"
                }}> {decodeHtmlEntities(location.name)}</Text>
                <br />
                <Text ellipsis={true} style={{
                    color: location._id === currentLocation ? "#4096ff" : "", textOverflow: "ellipsis",
                    maxWidth: "200px"
                }}>
                    {decodeHtmlEntities(location.address)}
                </Text>

            </span>
        ),
        key: location._id,
    }));

    if (!foundCurrentLocation) {
        return;
    }

    return (
        <Dropdown
            trigger={"click"}
            menu={{
                items,
                onClick: ({ key }) => {
                    handleChangeLocation(key);
                },
            }}
            placement='bottom'
        >
            <strong style={{ marginRight: "20px" }}>
                {isMobile ? (
                    <HomeTwoTone style={{ fontSize: "1.5em" }} />
                ) : (
                    <Text ellipsis={true} style={{
                        color: "#1677ff", textOverflow: "ellipsis",
                        maxWidth: "200px"
                    }}>
                        {" "}
                        {decodeHtmlEntities(foundCurrentLocation?.name)}{" "}
                        <DownOutlined />{" "}
                    </Text>
                )}
            </strong>
        </Dropdown>
    );
};


export default Locations;
